<template>
  <div>
    <div class="box-card">
      
      <!-- 外汇table切换暂时不用 -->

      <div v-if="[1, 2, 3, 5, 4 ].indexOf(courseType) === -1">
        <el-radio-group v-model="financeRadio" :disabled="disabled" size="small" @change="getDrafrList" style="margin:10px -10px;" text-color="#3B6EFB" fill="#FFFF">
          <el-radio-button label="证券" value="证券"/>
          <el-radio-button label="外汇" value="外汇"/>
        </el-radio-group>
      </div>
      <div>
        <div style="margin-bottom: 20px;float: left;"><i class="card-line"></i>过期报告</div>
        <div v-for="(item ,index) in reportList" :key="item.key" @click="tabBtn(item.router)" style="float: right;"><el-button v-if="item.name != '过期报告'" size="mini" :type="item.name == '新建'?'warning':'primary'" style="margin-right: 10px;"> {{ item.name }} </el-button></div>
      </div>
      <div style="margin: 10px 0;clear: both;">
        <!--      <el-row :gutter="20" style="margin: 10px 0">-->
        <!--        <el-col :span="6">-->
        <span class="report-title">报告类型</span>
        <el-select v-model="reportValue" placeholder="请选择" size="mini" clearable>
          <el-option v-for="item in reportOptions" :key="item.filetype" :label="item.type" :value="item.filetype">
          </el-option>
        </el-select>
        <span class="report-title">小组名称</span>
        <el-select v-model="groupValue" placeholder="请选择" size="mini" clearable>
          <el-option v-for="item in groupOption" :key="item.acId" :label="item.groupName" :value="item.acId">
          </el-option>
        </el-select>
        <span class="report-title">报告名称</span>
        <el-input v-model="fileName" placeholder="请输入名称" size="mini" style="width: 150px"></el-input>

        <span class="report-title">上传时间</span>
        <el-date-picker size="mini" style="width: 250px;" v-model="datePicker" type="daterange" @change="DateChange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <!-- <el-switch style="margin: 0 5px" v-model="goods" inline-prompt active-text="优秀" inactive-text="默认"></el-switch> -->
        <span class="report-title">是否优秀</span>
        <el-select v-model="goods" placeholder="请选择" size="mini" clearable style="width: 120px">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
        <el-button type="primary" style="margin-left: 20px;float: right;" size="mini" @click="getDrafrList" icon="el-icon-search">查询</el-button>

        <!--        </el-col>-->
        <!--        <el-col :span="6">-->
        <!--          -->
        <!--        </el-col>-->
        <!--        <el-col :span="9">-->
        <!--          -->
        <!--        </el-col>-->
        <!--        <el-col :span="3">-->
        <!--        </el-col>-->
        <!--      </el-row>-->
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="startList" :tableData="spreadTable"></spread-table>
    </div>

    <el-dialog v-model="showHtml" :close-on-click-modal="false" width="80%">
      <!-- <div style="font-size: 14px;font-weight: 600;margin: 10px 0;text-align: center;">查看报告</div> -->
      <!-- <wang-editor :value="reportHtml" :disable="true"></wang-editor> -->
      <div class="title" style="margin-top: -20px;">{{ showHtmlTitle }}</div>
      <div style="height:50vh; overflow: auto;color: #03032C;" v-html="reportHtml" ></div>
      <!-- <el-button style="margin: 15px 0" type="warning" size="mini" @click="showHtml = false">关闭</el-button> -->
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { startData } from "./browserData";
import WangEditor from '@/components/WangEnduitTry/index.vue';

import { clickButtons, currentChanges, sizeChanges, getDrafrLists, DateChanges, getGroupLists } from "./browserMethods";
import { useRoute, useRouter } from "vue-router";
import { getTypes } from "../UprePort-Edit/reportMethods";
import { findFileByFileId } from "../UprePort-Edit/editApi";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    WangEditor,
  },
  setup() {
    let router = useRouter();
    let route = useRoute();
    let data = reactive({
      showHtmlTitle:'',
      disabled:false,
      courseType:0,
      financeRadio:'证券',
      reportOptions: [],
      goods: false,
      startList: [
        { name: '周期', text: 'currentWeek' },
        { name: '小组名称', text: 'groupName' },
        { name: '报告类型', text: 'fileTypeName' },
        { name: '报告名称', text: 'name' },
        { name: '是否优秀', text: 'goodTypes' },
        { name: '报告状态', text: 'fileStateName' },
        { name: '报告上传者', text: 'userName' },
        { name: '上传时间', text: 'date' },
        { name: '上传状态', text: 'whetherState' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      tableHeight: 400,
      operation: ['查看'],
      pageType: 1,
      lastState: 1000,
      filetype: null,

      reportValue: undefined,
      fileName: undefined,
      datePicker: undefined,
      startTime: undefined,
      endTime: undefined,

      showHtml: false,
      reportHtml: '',
      groupOption: [],
      groupValue: '',
      reportList:[]
    })

    onMounted(() => {
      if(sessionStorage.getItem('reportList')){
        data.reportList=JSON.parse(sessionStorage.getItem('reportList'))
      }
      data.courseType = JSON.parse(sessionStorage.getItem('courseType'))
      if (route.query.item) {
        findFileByFileId({ fieldIds: [JSON.parse(route.query.item)] }).then(res => {
          if (res.data.code === '200') {
            data.showHtml = true
            let newRes = res.data.data
            data.reportHtml = newRes.tfundFundfiles[0].files
          }
        })
      }
      getDrafrList()
      getGroupList()
      getType(1)
    })
    let getType = (num) => { getTypes(num, data) }
    let getDrafrList = () => { getDrafrLists(data) }
    let getGroupList = () => { getGroupLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let DateChange = (val) => { DateChanges(val, data) }
    let clickButton = (val) => {
      data.showHtmlTitle = val.item.name
      clickButtons(val, data, router)
    }
    const tabBtn=(url)=>{
      router.push(url)
    }
    return {
      ...toRefs(data),
      getDrafrList,
      sizeChange,
      currentChange,
      DateChange,
      clickButton,
      tabBtn
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.report-title {
  margin-left: 20px;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
::v-deep.el-radio-button{
  .el-radio-button__inner {
    background: #FFFF ;
    color:#333;
    border: 0 !important;
    font-size: 16px !important;
  }
}
.box-card{
  margin-bottom: 100px;
}
:deep(.el-dialog__close.el-icon-close){
  &::before{
    content: "\e78d";
    font-size: 18px;
  }
}
.title{
    text-align: center;
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #03032C;
    line-height: 31px;
    margin-bottom: 20px;
  }
</style>
